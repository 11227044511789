import { Box, Indicator, Tooltip } from '@mantine/core'

import IconRightArrow from 'src/icons/IconRightArrow'
import { formatDate } from 'src/lib/formatters'

const ExpiredDocumentRow = ({ title, date, onClick = () => {} }) => {
  const hasExpired = new Date(date) < new Date()

  return (
    <Box
      className="flex flex-row gap-4 rounded-lg p-3 hover:bg-doubleNickel-brand-25"
      onClick={onClick}
    >
      <div className="text-sm font-medium text-doubleNickel-gray-700">
        {title}
      </div>
      <Tooltip
        label={hasExpired ? 'Expired' : 'Expires soon'}
        position="top"
        withArrow
      >
        <Indicator
          color={hasExpired ? 'red' : 'yellow'}
          position="middle-end"
          offset={-14}
          size={6}
        >
          <div className="text-sm font-medium text-doubleNickel-gray-500">
            {formatDate(date)}
          </div>
        </Indicator>
      </Tooltip>
      <IconRightArrow className="ml-auto h-4 fill-none stroke-doubleNickel-brand-500" />
    </Box>
  )
}

export default ExpiredDocumentRow
