import { Box } from '@mantine/core'

const MetricsCard = ({ title, value, onClick = () => {} }) => {
  return (
    <Box
      onClick={onClick}
      className="flex flex-row justify-between rounded-lg border border-doubleNickel-gray-200 bg-doubleNickel-gray-50 px-4 py-3 text-doubleNickel-gray-900 hover:border-doubleNickel-brand-500 hover:bg-doubleNickel-brand-25 hover:text-doubleNickel-brand-500"
    >
      <div className="text-sm font-semibold">{title}</div>
      <div className="text-md font-semibold">{value}</div>
    </Box>
  )
}

export default MetricsCard
