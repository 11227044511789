import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_APPLICANT_METRICS } from 'src/graphql/applicants.graphql'
import { ApplicantStage, HiringStage } from 'src/graphql/types/applicants'
import { formatSnakeValue } from 'src/lib/formatters'

import PipelineCard from './components/PipelineCard'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}
const ProspectInformation = ({ selectedRecruiters, onMetricClick }) => {
  const { data: hiringStageData, loading: loadingHiringStageMetrics } =
    useQuery(GET_APPLICANT_METRICS, {
      variables: {
        groupBy: 'hiringStage',
        filters: {
          applicantStages: {
            OR: [
              {
                applicantStage: ApplicantStage.PROSPECT,
              },
            ],
          },
          recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
        },
      },
      onError,
    })

  const prospectMetricsOrder = [
    HiringStage.LEAD,
    HiringStage.APPLICATION,
    HiringStage.QUALIFICATION,
    HiringStage.ONBOARDING,
  ]

  const hiringStageMetrics = prospectMetricsOrder.map((stage) => {
    const metric = hiringStageData?.applicantMetrics?.items.find(
      (item) => item.name === stage
    )
    return metric || { name: stage, value: 0 }
  })

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex flex-col gap-1">
        <div className="text-lg font-semibold text-doubleNickel-gray-900">
          Prospect Pipeline
        </div>
        <div className="text-sm text-doubleNickel-gray-900">
          Number of candidates throughout the application process
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-1 flex-col gap-4 sm:flex-row">
          {hiringStageMetrics.map((metric) => (
            <PipelineCard
              key={metric.name}
              title={formatSnakeValue(metric.name)}
              value={metric.value}
              onClick={() =>
                onMetricClick('applicantStages', `PROSPECT-${metric.name}`)
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProspectInformation
