import { Loader } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_STEPS_METRICS } from 'src/graphql/steps.graphql'
import { ApplicantStage } from 'src/graphql/types/applicants'
import { StepStatus } from 'src/graphql/types/steps'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import { formatStepType } from 'src/lib/formatters'

import DashboardRow from '../components/DashboardRow'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}
const ProspectChecklist = ({ selectedRecruiters, onMetricClick }) => {
  const { data: stepsData, loading: loadingStepsMetrics } = useQuery(
    GET_STEPS_METRICS,
    {
      variables: {
        filters: {
          recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
          OR: [
            {
              status: StepStatus.PENDING_REVIEW,
            },
          ],
          applicantStages: [ApplicantStage.PROSPECT],
        },
      },
      onError,
    }
  )

  const pendingActions = stepsData?.stepMetrics?.items

  return (
    <div className="flex flex-1 flex-col gap-4 sm:overflow-hidden ">
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-doubleNickel-gray-900">
          Review these prospects
        </div>
        <div className="text-sm text-doubleNickel-gray-900">
          These documents are still pending your review
        </div>
      </div>
      <div className="flex h-full flex-col overflow-auto ">
        {loadingStepsMetrics ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Loader size="md" />
          </div>
        ) : (
          <>
            {pendingActions?.map((metric) => (
              <DashboardRow
                key={metric.name}
                title={formatStepType(metric.name)}
                value={metric.value}
                showArrow={true}
                onClick={() =>
                  onMetricClick('steps', `${metric.name}-PENDING_REVIEW`)
                }
              />
            ))}
          </>
        )}
        {pendingActions && pendingActions.length === 0 && (
          <div className="flex flex-1 flex-col items-center justify-center  ">
            <IconEmptyInbox className="h-40 fill-none" />
            <div className="text-md font-medium text-doubleNickel-gray-800">
              Congrats!
            </div>
            <div className="text-sm text-doubleNickel-gray-600">
              You have no pending actions
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProspectChecklist
