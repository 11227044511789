import { Badge, Box } from '@mantine/core'

import IconRightArrow from 'src/icons/IconRightArrow'

const DashboardRow = ({
  title,
  value,
  showHover = true,
  showArrow = false,
  onClick = () => {},
}) => {
  return (
    <Box
      onClick={onClick}
      className={`flex h-[50px] flex-row items-center gap-4 border-b-[1px] border-b-doubleNickel-gray-100 px-4 py-2 text-doubleNickel-gray-700 hover:rounded-lg ${
        showHover &&
        'hover:bg-doubleNickel-brand-25 hover:text-doubleNickel-brand-500'
      } `}
    >
      <div className="text-sm font-medium">{title}</div>
      <Badge
        circle
        size="xs"
        className="border-doubleNickel-gray-200 bg-doubleNickel-gray-50 text-doubleNickel-gray-700"
      >
        {value}
      </Badge>
      {showArrow && (
        <IconRightArrow className="ml-auto h-4 fill-none stroke-doubleNickel-brand-500" />
      )}
    </Box>
  )
}

export default DashboardRow
