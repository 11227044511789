import { useState } from 'react'

import { navigate } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

import StyledAlert from '../../components/Feedback/StyledAlert/StyledAlert'

import ApplicantInformation from './applicantInformation/ApplicantInformation'
import DashboardHeader from './components/DashboardHeader'
import DocumentChecklist from './documentChecklist/DocumentChecklist'
import ProspectChecklist from './prospectChecklist/ProspectChecklist'
import ProspectInformation from './prospectInformation/ProspectInformation'

const DashboardPage = () => {
  const { currentUser } = useAuth()

  const getInitialSelectedRecruiters = () => {
    // Check if the user is defined, has an employeeId, and the role includes 'RECRUITER'
    if (currentUser?.employeeId && currentUser?.roles?.includes('RECRUITER')) {
      const fullName = `${currentUser.firstName} ${currentUser.lastName}`
      return [
        {
          label: fullName,
          value: currentUser.employeeId,
        },
      ]
    }
    // Return an empty array if any condition fails
    return []
  }

  const fullName = `${currentUser?.firstName} ${currentUser?.lastName}`
  const [selectedRecruiters, setSelectedRecruiters] = useState(
    getInitialSelectedRecruiters()
  )

  const onMetricClick = (key, value) => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set(key, value)

    const recruiterIds = selectedRecruiters
      .map((recruiter) => recruiter.value)
      .join(',')
    if (recruiterIds.length > 0) {
      queryParams.set('recruiters', recruiterIds)
    }
    navigate(`/applicants?${queryParams.toString()}`)
  }

  // Create a new date object for today, but reset the time to midnight.
  const todayAtMidnight = new Date()
  todayAtMidnight.setHours(0, 0, 0, 0)

  // Calculate 30 days ago from the above date, also at midnight.
  const timeAgo = new Date(todayAtMidnight)
  timeAgo.setDate(todayAtMidnight.getDate() - 30)

  return (
    <div className="flex h-full flex-row gap-4 px-5 pb-5 ">
      <div className="flex h-full flex-1 flex-col gap-5  ">
        {fullName && (
          <DashboardHeader
            name={fullName}
            selectedRecruiters={selectedRecruiters}
            setSelectedRecruiters={setSelectedRecruiters}
          />
        )}
        {/* ALERT */}
        {(selectedRecruiters.length > 1 ||
          selectedRecruiters[0]?.value !== currentUser.employeeId) && (
          <StyledAlert title={`You are now seeing other people's work`} />
        )}

        <div className="grid grid-cols-1 gap-8 overflow-auto sm:grid-cols-2 sm:overflow-hidden">
          <ProspectInformation
            selectedRecruiters={selectedRecruiters}
            onMetricClick={onMetricClick}
          />
          <ApplicantInformation
            selectedRecruiters={selectedRecruiters}
            timeAgo={timeAgo}
            onMetricClick={onMetricClick}
          />
          <ProspectChecklist
            selectedRecruiters={selectedRecruiters}
            onMetricClick={onMetricClick}
          />
          <DocumentChecklist
            recruiters={selectedRecruiters.map((recruiter) => recruiter.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
