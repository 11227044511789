import { useState } from 'react'

import { Loader, Accordion } from '@mantine/core'
import { StepType } from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Empty from 'src/components/Feedback/Empty/Empty'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_EXPIRING_DOCUMENT_BY_CATEGORY,
  GET_EXPIRING_DOCUMENT_SUMMARY,
} from 'src/graphql/documents.graphql'
import { formatStepType } from 'src/lib/formatters'

import DashboardRow from '../components/DashboardRow'
import ExpiredDocumentRow from '../components/ExpiredDocumentRow'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const DocumentChecklist = ({ recruiters }) => {
  const LIMIT = 50
  const [page, setPage] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const {
    data: expiringDocumentSummary,
    loading: loadingExpiringDocumentSummary,
  } = useQuery(GET_EXPIRING_DOCUMENT_SUMMARY, {
    variables: {
      filters: {
        recruiters: recruiters,
      },
    },
    onError,
  })

  const { data: expiringDocuments, loading: loadingExpiringDocuments } =
    useQuery(GET_EXPIRING_DOCUMENT_BY_CATEGORY, {
      variables: {
        filters: {
          recruiters: recruiters,
          ...(selectedCategory !== null && { categories: [selectedCategory] }),
        },
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      },
      onError,
    })

  const handleDocumentChange = (category) => {
    setSelectedCategory(category)
  }

  return (
    <div className="flex flex-1 flex-col gap-4 sm:overflow-hidden">
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-doubleNickel-gray-900">
          Renew these documents
        </div>
        <div className="text-sm text-doubleNickel-gray-900">
          These documents will expire soon
        </div>
      </div>
      <div className="flex h-full flex-col gap-1 overflow-auto ">
        {loadingExpiringDocumentSummary ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Loader size="md" />
          </div>
        ) : (
          <>
            {expiringDocumentSummary?.documentsExpirationSummary &&
            expiringDocumentSummary.documentsExpirationSummary.length > 0 ? (
              <Accordion>
                {expiringDocumentSummary.documentsExpirationSummary.map(
                  (category) => (
                    <Accordion.Item
                      key={category.name}
                      value={category.name}
                      className="border-none"
                    >
                      <Accordion.Control
                        className="h-[50px] rounded-lg pl-0"
                        onClick={() => handleDocumentChange(category.name)}
                      >
                        <DashboardRow
                          title={formatStepType(category.name as StepType)}
                          value={category.value}
                          showArrow={false}
                          showHover={false}
                        />
                      </Accordion.Control>
                      <Accordion.Panel>
                        <div className="flex flex-col">
                          {loadingExpiringDocuments ? (
                            <div className="flex flex-1 flex-col items-center justify-center">
                              <Loader size="md" />
                            </div>
                          ) : (
                            expiringDocuments?.documentsExpirationByCategory &&
                            expiringDocuments.documentsExpirationByCategory.items.map(
                              (document) => (
                                <Link
                                  key={document.documentId}
                                  to={routes.applicantDetails({
                                    id: document.applicant.applicantId,
                                    tab: 'documents',
                                    dqf: document.category,
                                  })}
                                >
                                  <ExpiredDocumentRow
                                    title={document.applicant.fullName}
                                    date={document.expirationDate}
                                  />
                                </Link>
                              )
                            )
                          )}
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>
                  )
                )}
              </Accordion>
            ) : (
              <Empty
                title="Congrats"
                subtitle="You have no pending documents to renew"
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DocumentChecklist
