import { useQuery } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_APPLICANT_METRICS } from 'src/graphql/applicants.graphql'
import { ApplicantStage } from 'src/graphql/types/applicants'
import { formatSnakeValue } from 'src/lib/formatters'

import MetricsCard from './components/MetricsCard'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}
const ApplicantInformation = ({
  selectedRecruiters,
  timeAgo,
  onMetricClick,
}) => {
  const { data: applicantStageData, loading: loadingApplicantStageMetrics } =
    useQuery(GET_APPLICANT_METRICS, {
      variables: {
        groupBy: 'applicantStage',
        filters: {
          recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
          applicationDate: {
            gte: timeAgo.toISOString(),
          },
        },
      },
      onError,
    })

  const keyMetricsOrder = [
    ApplicantStage.HIRED,
    ApplicantStage.DISQUALIFIED,
    ApplicantStage.NOT_INTERESTED,
    ApplicantStage.TERMINATED,
  ]

  const applicantStageMetrics = keyMetricsOrder.map((stage) => {
    const metric = applicantStageData?.applicantMetrics?.items.find(
      (item) => item.name === stage
    )
    return metric || { name: stage, value: 0 }
  })

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex flex-col gap-1">
        <div className="text-lg font-semibold text-doubleNickel-gray-900">
          Applicants
        </div>
        <div className="text-sm text-doubleNickel-gray-900">
          Applicant statuses from the last 30 days
        </div>
      </div>
      <div className="flex flex-col gap-2 sm:flex-row">
        <div className="flex flex-1 flex-col gap-2">
          {applicantStageMetrics.slice(0, 2).map((metric) => (
            <MetricsCard
              key={metric.name}
              title={formatSnakeValue(metric.name)}
              value={metric.value}
              onClick={() => onMetricClick('applicantStages', metric.name)}
            />
          ))}
        </div>
        <div className="flex flex-1 flex-col gap-2">
          {applicantStageMetrics.slice(2, 4).map((metric) => (
            <MetricsCard
              key={metric.name}
              title={formatSnakeValue(metric.name)}
              value={metric.value}
              onClick={() => onMetricClick('applicantStages', metric.name)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ApplicantInformation
