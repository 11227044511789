import { useContext, useRef } from 'react'

import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import CheckboxMenu from 'src/components/Overlays/CheckboxMenu/CheckboxMenu'
import IconFilter from 'src/icons/IconFilter'
import { RecruitersContext } from 'src/context/RecruitersContext'

const DashboardHeader = ({
  name,
  selectedRecruiters,
  setSelectedRecruiters,
}) => {
  const buttonRef = useRef(null)
  const recruiters = useContext(RecruitersContext)

  return (
    <div className="flex flex-col justify-between gap-4 text-3xl font-semibold text-doubleNickel-brand-500 sm:flex-row">
      Welcome, {name}!
      <CheckboxMenu
        items={recruiters}
        selectedItems={selectedRecruiters}
        setSelectedItems={setSelectedRecruiters}
        fallbackText={'No recruiters found'}
      >
        <Button
          ref={buttonRef}
          variant="outline"
          text={`Recruiter: ${
            selectedRecruiters.length === 0 ? 'All' : selectedRecruiters.length
          }`}
          lefticon={<IconFilter />}
        />
      </CheckboxMenu>
    </div>
  )
}

export default DashboardHeader
