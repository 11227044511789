import { Box } from '@mantine/core'

const PipelineCard = ({ title, value, onClick }) => {
  return (
    <Box
      onClick={onClick}
      className="item-center flex flex-1 flex-col justify-center gap-2 rounded-lg border border-doubleNickel-gray-200 p-4 text-doubleNickel-gray-900 hover:border-doubleNickel-brand-500 hover:bg-doubleNickel-brand-25 hover:text-doubleNickel-brand-500"
    >
      <div className="text-xs">{title}</div>
      <div className="text-3xl font-medium ">{value}</div>
    </Box>
  )
}

export default PipelineCard
